import 'lazysizes'
import loadComponents from './global/loadComponents'
import Navigation from './components/navigation'
import Cart from './components/cart'

class Page {
  constructor() {
    this.init()
  }

  init() {
    loadComponents(document)

    new Navigation()
    new Cart()
  }
}

window.addEventListener('load', () => {
  new Page()
})
