import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export default class Navigation {
  constructor() {
    this.navigation = document.querySelector('[data-component="navigation"]')
    this.openBtn = document.querySelector('[data-burger]')
    this.closeBtn = this.navigation.querySelector('[data-close]')
    this.overlay = this.navigation.querySelector('[data-overlay]')
    this.content = this.navigation.querySelector('[data-content]')

    this.init()
  }

  open() {
    disableBodyScroll(this.content)
    document.body.classList.add('is-open')
  }

  close() {
    enableBodyScroll(this.content)
    document.body.classList.remove('is-open')
  }

  init() {
    this.openBtn.addEventListener('click', this.open.bind(this))
    this.closeBtn.addEventListener('click', this.close.bind(this))
    this.overlay.addEventListener('click', this.close.bind(this))
  }
}
