import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export default class Cart {
  constructor() {
    this.cart = document.getElementById('snipcart')
    this.observer = undefined

    this.init()
  }

  open() {
    disableBodyScroll(this.cart)
  }

  close() {
    enableBodyScroll(this.cart)
  }

  mutationCallback(mutationsList) {
    mutationsList.forEach((mutation) => {
      if (mutation.attributeName == 'class') {
        if (mutation.target.classList.contains('snipcart-sidecart--opened')) {
          this.open()
        } else {
          this.close()
        }
      }
    })
  }

  setupMutationObserver() {
    this.observer = new MutationObserver(this.mutationCallback.bind(this))
    this.observer.observe(document.documentElement, {
      attributes: true,
      childList: false,
      subtree: false,
    })
  }

  init() {
    this.setupMutationObserver()
  }
}
